{
  "settings": {
    "main_settings": "Main Settings",
    "main_settings_subtitle": "Platform Data, Social Media",
    "system_users": "System Users",
    "system_users_subtitle": "Users, Permissions, Transaction History",
    "profile": "User Data",
    "profile_subtitle": "Modify your personal data",
    "keywords": "Tags",
    "keywords_subtitle": "Add and Edit Tags",
    "password": "password",
    "advance_setting": "Advanced Settings",
    "language": "language",
    "personal_information": "Personal Data",
    "notifications_setting": "Notification Sound",
    "welcome_user": "Welcome",
    "update_personal_information": "Modify Personal Data",
    "change_password": "Modify Password",
    "reset_password": "Reset Password"
  },
  "videos": {
    "table": {
      "profile": "Name",
      "section": "folder",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "title of the video",
      "file": "video link",
      "section": "Video Location",
      "section_placeholder": "Select video location"
    },
    "modal": {
      "new": "Add Video",
      "update": "Edit a video"
    }
  },
  "photos": {
    "table": {
      "profile": "Name",
      "section": "folder",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "image title",
      "file": "image file",
      "section": "image location",
      "section_placeholder": "Select Image Location"
    },
    "modal": {
      "new": "add image",
      "update": "Update Image"
    }
  },
  "ads": {
    "title": "Thumbnail Ads",
    "table": {
      "profile": "Advertisement",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "Name",
      "image": "image"
    },
    "modal": {
      "new": "Add Ad",
      "update": "Modify an ad"
    }
  },
  "reports": {
    "table": {
      "profile": "Name",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "Title of the report",
      "file": "report file",
      "image": "image of the report"
    },
    "modal": {
      "new": "Add Report",
      "update": "Modify a report"
    }
  },
  "magazines": {
    "table": {
      "profile": "Name",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "Journal title",
      "file": "Journal File",
      "image": "magazine image"
    },
    "modal": {
      "new": "Add Magazine",
      "update": "Modify Journal"
    }
  },
  "folders": {
    "table": {
      "profile": "Name",
      "items_count": "Number of Files",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "table_actions": {
      "edit": "edit",
      "delete": "delete"
    },
    "form": {
      "title": "folder name",
      "file": "folder image",
      "image": "folder image"
    },
    "modal": {
      "new": "add folder",
      "update": "Modify Folder"
    }
  },
  "management": {
    "table": {
      "title": "Department Name",
      "items_count": "Number of Members",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "table_actions": {
      "edit": "edit",
      "delete": "delete"
    },
    "form": {
      "title": "Department Name"
    },
    "modal": {
      "new": "Add Administration",
      "update": "Modify Management"
    }
  },
  "sections": {
    "table": {
      "profile": "Name",
      "items_count": "Number of Cards",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "table_actions": {
      "edit": "edit",
      "delete": "delete"
    },
    "form": {
      "title": "Section Name",
      "file": "Partition Image",
      "image": "section image"
    },
    "modal": {
      "new": "Add Section",
      "update": "Modify a section"
    }
  },
  "credit-cards": {
    "title": "Cards",
    "table": {
      "profile": "Name",
      "section": "section",
      "description": "Description",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "sections": {
      "title": "sections"
    },
    "form": {
      "image": "card image",
      "title": "tag name",
      "file": "Card Application Document",
      "section": "section",
      "section_placeholder": "select section",
      "description": "description"
    },
    "modal": {
      "new": "add card",
      "update": "Modify Card"
    },
    "update": {
      "title": "Edit Tag"
    },
    "create": {
      "title": "Add Card"
    }
  },
  "slider": {
    "title": "Slideshow",
    "table": {
      "profile": "Name",
      "slide_title": "Paragraph Title",
      "content": "content",
      "period": "period",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "name": "Name",
      "title": "paragraph title",
      "image": "paragraph image",
      "content": "content",
      "period": "period",
      "btn_link": "Birth Place",
      "btn_primary": "Primary Button Text",
      "btn_secondary": "Secondary Button Text"
    },
    "update": {
      "title": "Edit Ad"
    },
    "create": {
      "title": "Add New Ad"
    }
  },
  "about": {
    "title": "About the Bank",
    "table": {
      "profile": "Department",
      "section_title": "Section Title",
      "content": "content",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "name": "Name",
      "title": "paragraph title",
      "image": "paragraph image",
      "content": "content"
    },
    "update": {
      "title": "Edit Section"
    }
  },
  "home": {
    "title": "Home Page Content",
    "table": {
      "profile": "Name",
      "section_title": "Section Title",
      "content": "content",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "name": "Name",
      "title": "paragraph title",
      "image": "paragraph image",
      "content": "content"
    },
    "update": {
      "title": "Edit Section"
    }
  },
  "pages": {
    "title": "Compliance Policy",
    "table": {
      "title": "Title",
      "content": "content",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "name": "Name",
      "title": "paragraph title",
      "image": "paragraph image",
      "content": "content"
    },
    "update": {
      "title": "Edit Section"
    }
  },
  "branch": {
    "title": "Branches",
    "table": {
      "name": "Branch Name",
      "address": "branch address",
      "governorate": "to maintain",
      "phone": "mobile number",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "geo_location": "Branch address on the map",
      "name": "Branch Name",
      "address": "branch address",
      "gov": "province",
      "gov_placeholder": "select province",
      "phone": "mobile number"
    },
    "create": {
      "title": "Add a new branch"
    },
    "update": {
      "title": "Modify a branch"
    }
  },
  "atm": {
    "title": "ATM Network",
    "table": {
      "name": "Name of the teller",
      "address": "The address of the teller",
      "governorate": "to maintain",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "geo_location": "The address of the cashier on the map",
      "name": "Name of the teller",
      "address": "The address of the teller",
      "gov": "province",
      "gov_placeholder": "select province"
    },
    "create": {
      "title": "Add a new teller"
    },
    "update": {
      "title": "Modify changer"
    }
  },
  "pos": {
    "title": "POS",
    "table": {
      "name": "point name",
      "address": "point address",
      "governorate": "to maintain",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "geo_location": "Point address on the map",
      "name": "point name",
      "address": "point address",
      "gov": "province",
      "gov_placeholder": "select province"
    },
    "create": {
      "title": "Add a new point"
    },
    "update": {
      "title": "Modify point"
    }
  },
  "news": {
    "title": "News",
    "table": {
      "profile": "Title",
      "content": "content",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "sections": {
      "title": "sections"
    },
    "form": {
      "image": "the image of the news",
      "title": "the name of the news",
      "content": "content"
    },
    "update": {
      "title": "edit news"
    },
    "create": {
      "title": "Add News"
    }
  },
  "applications": {
    "title": "Bank Applications",
    "table": {
      "profile": "Title",
      "description": "content",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "sections": {
      "title": "sections"
    },
    "form": {
      "image": "application image",
      "file": "application file",
      "title": "The name of the application",
      "content": "content",
      "app_store_link": "Apple Store Link",
      "google_play_link": "Google Play Link"
    },
    "update": {
      "title": "Modify Application Data"
    },
    "create": {
      "title": "Add an app"
    }
  },
  "faq": {
    "title": "Frequently Asked Questions",
    "table": {
      "question": "the content of the question",
      "answer": "answer content",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "question": "the content of the question",
      "answer": "answer content"
    },
    "modal": {
      "new": "add question",
      "update": "Modify a question"
    }
  },
  "contacts": {
    "title": "Social media sites",
    "table": {
      "profile": "platform",
      "value": "link",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "icon": "platform icon",
      "name": "platform name",
      "value": "platform link"
    },
    "modal": {
      "new": "Add a new platform",
      "update": "Modify platform"
    }
  },
  "staff": {
    "title": "Board of Directors",
    "management": {
      "title": "Departments"
    },
    "table": {
      "profile": "Name",
      "job": "position",
      "management": "management",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "name": "User Name",
      "job": "position",
      "management": "management",
      "management_placeholder": "select management",
      "image": "image"
    },
    "create": {
      "title": "Add a new member"
    },
    "update": {
      "title": "Edit Member"
    },
    "modal": {
      "new": "add new member",
      "update": "Modify a member"
    }
  },
  "main-services": {
    "title": "Services",
    "table": {
      "profile": "main service name",
      "items_count": "Number of Services",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    }
  },
  "sub-services": {
    "title": "Services",
    "table": {
      "profile": "Name",
      "items_count": "Sub-Services",
      "content": "content",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "the name of the service",
      "content": "content",
      "image": "image",
      "switch": "Show details"
    },
    "modal": {
      "new": "Add a new service",
      "update": "Modify Service"
    }
  },
  "jawaher_extra": {
    "title": "Additional Content",
    "table": {
      "profile": "Title",
      "content": "content",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "the name of the service",
      "content": "content",
      "image": "image"
    },
    "update": {
      "title": "Modify Content"
    }
  },
  "jawaher_awards": {
    "title": "Awards",
    "table": {
      "profile": "Award Name",
      "content": "Description",
      "count": "Number of Trophies",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "The name of the award",
      "count": "Number of Trophies",
      "content": "Description",
      "image": "image"
    },
    "modal": {
      "update": "Update Content"
    }
  },
  "jawaher_awards_section": {
    "title": "Awards",
    "table": {
      "title": "paragraph title",
      "content": "Description",
      "actions": "actions"
    },
    "form": {
      "title": "paragraph title",
      "content": "Description"
    },
    "update": {
      "title": "Modify Content"
    }
  },
  "sub-subservices": {
    "title": "Services",
    "table": {
      "profile": "Name",
      "content": "content",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "the name of the service",
      "content": "content",
      "image": "image",
      "file": "Service file"
    },
    "create": {
      "title": "Add New Service"
    },
    "update": {
      "title": "Modify Service"
    }
  },
  "terms": {
    "title": "Terms and Benefits",
    "table": {
      "title": "paragraph title",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "content"
    },
    "create": {
      "title": "Add a new feature"
    },
    "update": {
      "title": "Edit Feature"
    }
  },
  "winners": {
    "title": "Jawaher Winners",
    "groups": {
      "title": "Custom Sections",
      "draws": "draws",
      "awards": "awards"
    },
    "table": {
      "profile": "Name",
      "award": "award",
      "branch": "branch",
      "year": "year",
      "draw": "draw",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "Name of the Winner",
      "award": "award",
      "award_placeholder": "Select the award",
      "branch": "branch",
      "branch_placeholder": "select branch",
      "year": "year",
      "year_placeholder": "select year",
      "draw": "draw",
      "draw_placeholder": "Select Draw"
    },
    "modal": {
      "new": "Add New Winner",
      "update": "Edit Winner"
    }
  },
  "draws": {
    "title": "Clouds",
    "table": {
      "title": "The name of the draw",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "the name of the cloud"
    },
    "modal": {
      "create": "Add New Cloud",
      "update": "Modify Checkout"
    }
  },
  "awards": {
    "title": "Awards",
    "table": {
      "title": "The name of the award",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "The name of the award"
    },
    "modal": {
      "create": "Add a new prize",
      "new": "Add a new prize",
      "update": "Modify Award"
    }
  },
  "keywords": {
    "table": {
      "title_en": "The Tag in Arabic",
      "title_en": "Tag in English",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "form": {
      "title": "Tag"
    },
    "modal": {
      "new": "Add a new tag",
      "update": "Modify Tag"
    }
  },
  "companies": {
    "company_details": "Company Details",
    "company_update": "Update Company Data",
    "table": {
      "title": "company name",
      "total_points": "Total Points",
      "spent_points": "spent points",
      "total_users": "Number of Users",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "table_actions": {
      "details": "company details"
    },
    "statics": {
      "total_clients": "Number of Clients",
      "total_users": "Number of Users",
      "total_points": "Total Points",
      "current_points": "Remaining Points",
      "used_point": "Used Points"
    },
    "company_information": "Company Information",
    "subscriptions": "subscribers",
    "activate": "Activate the ability to subscribe",
    "disable": "Disable"
  },
  "clients": {
    "client_details": "User Details",
    "total_integrations": "Subscriptions",
    "gender": {
      "m": "male",
      "f": "female"
    },
    "birth_date": "birthdate",
    "table": {
      "name": "User Name",
      "phone": "phone number",
      "gender": "the gender",
      "email": "email",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "table_actions": {
      "details": "View details",
      "send_notification": "Send a Notification"
    }
  },
  "client-integrations": {
    "title": "Subscriptions",
    "table": {
      "profile": "Company Name",
      "total_points": "Total Points",
      "spent_points": "spent points",
      "current_points": "Remaining Points",
      "created_at": "Subscription Date",
      "actions": "actions"
    },
    "table_actions": {
      "show": "Show transaction log"
    }
  },
  "company-integrations": {
    "title": "Subscriptions",
    "table": {
      "profile": "Username",
      "total_points": "Number of Points",
      "spent_points": "spent points",
      "current_points": "Remaining Points",
      "created_at": "Subscription Date",
      "actions": "actions"
    },
    "table_actions": {
      "show": "Show transaction log"
    }
  },
  "points": {
    "title": "Transaction History",
    "type_id": {
      "0": "points spent",
      "1": "Points Earned"
    },
    "table": {
      "type_id": "Type of operation",
      "total_points": "Total Points",
      "created_at": "The date of the operation"
    }
  },
  "integrations": {
    "title": "Subscriptions"
  },
  "advertisements": {
    "create_new": "Add New Ad",
    "table": {
      "profile": "Advertisement",
      "created_at": "date added",
      "status": "on/off",
      "actions": "actions"
    },
    "table_actions": {
      "alter": "Modify Ad",
      "delete": "delete the ad"
    }
  },
  "notifications": {
    "send_new_notification": "Add a new notification",
    "table": {
      "title": "Notice Name",
      "profile": "Notice Title",
      "content": "Notice Content",
      "target": "target",
      "company_name": "Orientation",
      "created_at": "date added",
      "actions": "actions"
    },
    "table_actions": {
      "resend": "resend"
    },
    "targets": "target group",
    "add_target": "Add Target Category",
    "client": "client",
    "all_users": "all users",
    "specific_user": "Specific Users",
    "filtered_users": "Other Categories",
    "by_age": "by age",
    "gender": "sex",
    "all": "all",
    "specific": "custom",
    "male_only": "male only",
    "female_only": "women only",
    "specific_age": "age",
    "from_to": "from--to--",
    "date_error": "The start field must be greater than the end"
  },
  "system-roles": {
    "create_new": "Add a new permission",
    "update_role": "Modify Validity",
    "delete": "delete",
    "table": {
      "name": "The validity name",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "table_actions": {
      "view": "view details"
    }
  },
  "news-letters": {
    "table": {
      "profile": "Title",
      "created_at": "Date Added"
    }
  },
  "permissions": {
    "sections": {
      "dashboard": "home",
      "companies": "companies",
      "clients": "users",
      "ads": "ads",
      "notifications": "notifications",
      "settings": "settings",
      "platform": "platform settings",
      "roles": "powers",
      "admin_users": "System Users",
      "user_profile": "Profile Settings",
      "staff": "board of directors",
      "social": "Access Data",
      "sliders": "slide show",
      "site-content-home": "Home Page Data",
      "site-content-about": "about the bank",
      "services": "services",
      "news": "news",
      "pages": "Compliance Policy",
      "logs": "Transaction Log",
      "location_(pos)": "Location (POS)",
      "location_(branch)": "Location (branch)",
      "location_(atm)": "Location (ATM)",
      "library_(photos)": "Media Center (photos)",
      "library_(videos)": "Media Center (video)",
      "library_(reports)": "Media Center (Reports)",
      "library_(magazines)": "Media Center (magazines)",
      "contacts": "contact information",
      "faqs": "Frequently Asked Questions",
      "home": "Home Page Data",
      "cards": "cards",
      "jawaher_faqs": "[Jawaher] Frequently Asked Questions",
      "jawaher_sliders": "[Jawaher] Slide Show",
      "jawaher_videos": "[Jawaher] videos",
      "jawaher_photos": "[Jawaher] photos",
      "jawaher-about": "[jawaher] about the bank",
      "jawaher-award": "[jewels] awards",
      "jawaher-winners": "[jewels] winners",
      "jawaher-extra": "[jewels] extra data",
      "jawaher-terms": "[jawaher] terms and conditions",
      "jawaher-applications": "[jewels] applications",
      "jawaher-winners-groups": "[Jawaher] Winners' Divisions",
      "applications": "bank applications",
      "messages": "technical support",
      "seo_keywords": "Tags",
      "support": "Technical Support - Newsletters"
    },
    "read_news_letters": "Show subscribers in the newsletter",
    "read_seo_keywords": "Display Tags",
    "edit_seo_keywords": "Edit Tags",
    "add_seo_keywords": "Add Tags",
    "delete_seo_keywords": "Delete Keywords",
    "read_messages": "Display Messages",
    "delete_messages": "delete messages",
    "read_applications": "View Bank Applications",
    "edit_applications": "Edit Bank Applications",
    "add_applications": "Add Bank Applications",
    "delete_applications": "Delete Bank Applications",
    "read_contacts": "View contact information",
    "edit_contacts": "Edit contact information",
    "read_pages": "View Compliance Policy",
    "edit_pages": "Edit Compliance Policy",
    "read_faqs": "View Frequently Asked Questions",
    "edit_faqs": "Edit FAQ",
    "add_faqs": "Add Frequently Asked Questions",
    "delete_faqs": "Delete FAQs",
    "read_cards": "Display Cards",
    "edit_cards": "Edit Cards",
    "add_cards": "Add Cards",
    "delete_cards": "delete cards",
    "read_card_sections": "Display Card Sections",
    "edit_card_sections": "Edit Card Sections",
    "add_card_sections": "Add Card Sections",
    "delete_card_sections": "Delete Card Sections",
    "read_home": "Display Home Page Data",
    "read_jawaher_faqs": "[Jawaher] View Frequently Asked Questions",
    "edit_jawaher_faqs": "[Jawaher] Edit FAQ",
    "add_jawaher_faqs": "[Jawaher] Add FAQ",
    "delete_jawaher_faqs": "[Jawaher] Delete Frequently Asked Questions",
    "read_jawaher_sliders": "[Jawaher] Slide Show",
    "edit_jawaher_sliders": "[Jawaher] Editing Slideshow",
    "add_jawaher_sliders": "[Jewals] Add Slideshow",
    "delete_jawaher_sliders": "[Jawaher] Delete Slideshow",

    "read_jawaher_videos": "[Jawaher] View Videos",
    "edit_jawaher_videos": "[Jawaher] Edit Videos",
    "add_jawaher_videos": "[Jawaher] Add Videos",
    "delete_jawaher_videos": "[Jawaher] Delete Videos",
    "read_jawaher_videos_folder": "[Jawaher] View Video Folders",
    "edit_jawaher_videos_folder": "[Jawaher] Edit Video Folders",
    "add_jawaher_videos_folder": "[Jawaher] Add Video Folders",
    "delete_jawaher_videos_folder": "[Jawaher] Delete Video Folders",

    "read_jawaher_photos": "[Jawaher] View Photos",
    "edit_jawaher_photos": "[Jawaher] Edit Photos",
    "add_jawaher_photos": "[Jawaher] Add Photos",
    "delete_jawaher_photos": "[Jawaher] Delete Photos",
    "read_jawaher_photos_folder": "[Jawaher] View Photo Folders",
    "edit_jawaher_photos_folder": "[Jawaher] Edit Photo Folders",
    "add_jawaher_photos_folder": "[Jawaher] Add Photo Folders",
    "delete_jawaher_photos_folder": "[Jawaher] Delete Photo Folders",


    "read_jawaher_about_sections": "[Jawaher] Show page details about the bank",
    "edit_jawaher_about_sections": "[Jawaher] Edit page details about the bank",
    "read_jawaher_award_sections": "[Jewals] View Awards",
    "edit_jawaher_award_sections": "[Jewals] Edit Awards",
    "read_jawaher_applications": "[Jewals] View Applications",
    "edit_jawaher_applications": "[Jewals] Edit Applications",
    "add_jawaher_applications": "[Jewals] Add Applications",
    "delete_jawaher_applications": "[Jewals] Delete Applications",
    "read_jawaher_winners": "[Jawaher] Show Winners",
    "edit_jawaher_winners": "[Jewals] Edit Winners",
    "add_jawaher_winners": "[Jewals] Add Winners",
    "delete_jawaher_winners": "[Jawaher] Delete Winners",
    "read_jawaher_extra_sections": "[Jewals] Show Extra Data",
    "edit_jawaher_extra_sections": "[Jewah] Edit Extra Data",
    "read_jawaher_terms": "[Jawaher] View Terms and Conditions",
    "edit_jawaher_terms": "[Jawaher] Edit Terms and Conditions",
    "add_jawaher_terms": "[Jawaher] Add Terms and Conditions",
    "delete_jawaher_terms": "[Jawaher] Delete Terms and Conditions",
    "read_jawaher_winners_groups": "[Jawaher] Show Winners Divisions",
    "edit_jawaher_winners_groups": "[Jawaher] Edit Winners Categories",
    "add_jawaher_winners_groups": "[Jawaher] Add Winners Sections",
    "delete_jawaher_winners_groups": "[Jawaher] Delete Winners Categories",
    "read_home_sections": "Display Home Page Data",
    "edit_home_sections": "Edit Home Page Data",
    "read_about_sections": "Display bank page information",
    "edit_about_sections": "Edit about bank page details",
    "read_location_pos": "View Bank Location (POS)",
    "edit_location_pos": "Edit Bank Location (POS)",
    "add_location_pos": "Add Bank Location (POS)",
    "delete_location_pos": "Delete Bank Location (POS)",
    "read_location_branch": "Display the location of the bank (branch)",
    "edit_location_branch": "Edit Bank (Branch) Location",
    "add_location_branch": "Add Bank Location (Branch)",
    "delete_location_branch": "Delete the location of the bank (branch)",
    "read_location_atm": "View Bank Location (ATM)",
    "edit_location_atm": "Edit Bank Location (ATM)",
    "add_location_atm": "Add Bank Location (ATM)",
    "delete_location_atm": "Delete Bank Location (ATM)",
    "read_library_photos": "View Photo Library",
    "edit_library_photos": "Edit Photo Library",
    "add_library_photos": "add photo",
    "delete_library_photos": "delete a photo",
    "read_library_folder_photos": "Display Photo Library Folders",
    "edit_library_folder_photos": "Edit Photo Library Folders",
    "add_library_folder_photos": "Add Folder",
    "delete_library_folder_photos": "Delete a folder",
    "read_library_videos": "View Video Library",
    "edit_library_videos": "Edit Library Videos",
    "add_library_videos": "Add a Video",
    "delete_library_videos": "delete a video",
    "read_library_folder_videos": "View Video Library Folders",
    "edit_library_folder_videos": "Edit Library Folders",
    "add_library_folder_videos": "Add Folder",
    "delete_library_folder_videos": "delete folder",
    "read_library_reports": "View Report Library",
    "edit_library_reports": "Edit Report Library",
    "add_library_reports": "Add a Report",
    "delete_library_reports": "Delete a report",
    "read_library_magazines": "View Magazine Library",
    "edit_library_magazines": "Edit Library of Magazines",
    "add_library_magazines": "Add a Magazine",
    "delete_library_magazines": "Delete a magazine",
    "read_services": "View Services",
    "edit_services": "Edit Services",
    "add_services": "Add Service",
    "delete_services": "delete a service",
    "read_news": "Display News",
    "edit_news": "Edit News",
    "add_news": "add news",
    "delete_news": "delete news",
    "read_logs": "View transaction log",
    "add_sliders": "Add Slideshow",
    "read_sliders": "Slide Show",
    "edit_sliders": "Edit Slideshow",
    "delete_sliders": "Delete Slideshow",
    "add_staff": "add member",
    "read_staff": "View Member",
    "edit_staff": "Edit a member",
    "delete_staff": "delete a member",
    "add_staff_sections": "Add Staff",
    "read_staff_sections": "Display Management",
    "edit_staff_sections": "Edit Staff",
    "delete_staff_sections": "delete a section",
    "read_social": "View Connection Data",
    "edit_social": "Edit Access Data",
    "read_dashboard": "Home view",
    "read_companies": "View Companies",
    "edit_companies": "Edit Companies",
    "delete_companies": "delete companies",
    "read_clients": "View Users",
    "edit_clients": "Edit Users",
    "delete_clients": "Delete Users",
    "read_ads": "Display Ads",
    "add_ads": "add ads",
    "edit_ads": "Edit Ads",
    "delete_ads": "Delete Ads",
    "read_notifications": "Display Notifications",
    "send_notifications": "Send Notifications",
    "edit_notifications": "Edit Notifications",
    "delete_notifications": "Delete Notifications",
    "read_platform": "Display Platform Information",
    "edit_platform": "Edit platform information",
    "read_roles": "View Permissions",
    "add_roles": "Add Permissions",
    "edit_roles": "Edit Permissions",
    "delete_roles": "Delete Permissions",
    "read_admin_users": "Display System Users",
    "add_admin_users": "Add System Users",
    "edit_admin_users": "Edit System Users",
    "delete_admin_users": "Delete System Users",
    "read_admin_users_logs": "View System Users Log",
    "reset_password_admin_users": "Reset system users password"
  },
  "system-users": {
    "create_new": "Add a new user",
    "view_logs": "View Transaction Log",
    "roles_management": "Rules Management",
    "logs": "Transaction Log",
    "resend_password": "Resend Password",
    "delete": "delete the user",
    "table": {
      "profile": "Username",
      "email": "email",
      "role": "named",
      "last_activity": "Last Activity",
      "created_at": "date added",
      "status": "status",
      "actions": "actions"
    },
    "table_actions": {
      "view": "view details"
    }
  },
  "logs": {
    "table": {
      "user": "username",
      "title": "The name of the process",
      "content": "Transaction Details",
      "created_date_time": "The date of the operation"
    }
  },
  "dashboard": {
    "messages": "New Emails",
    "newsletters": "Newsletter Requests",
    "view_all": "view all",
    "statics": {
      "title": "Statistics",
      "total_clients": "Number of Clients",
      "total_companies": "Total Companies",
      "total_points": "Total Points",
      "current_points": "Remaining Points",
      "used_point": "Used Points"
    },
    "points_collecting_avarage": "Points Collection Rate",
    "last_7_days": "last 7 days",
    "last_30_days": "last 30 days",
    "last_12_months": "last 12 months",
    "clients_gender": "User Type",
    "males": "males",
    "females": "females",
    "highest_company_avarage": "Highest Company Availability",
    "point": "point",
    "client_joining_avarage": "User Joining Rate",
    "request": "request",
    "resent_users": "Recent Users",
    "user": "User",
    "aquire": "points earned",
    "spent": "spent points"
  },
  "forms_required": "This field is required",
  "inputs": {
    "title_ar": "Arabic name",
    "title_en": "English name",
    "title": "name",
    "name": "name",
    "email": "Email",
    "phone": "phone number",
    "password": "password",
    "password_confirmation": "Confirm password",
    "status": "status",
    "active": "activated",
    "content_ar": "Content in Arabic",
    "content_en": "Content in English",
    "image": "image",
    "images": "images",
    "video": "video",
    "file": "file"
  }
}