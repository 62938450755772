{
  "settings": {
    "main_settings": "الإعدادات الأساسية",
    "main_settings_subtitle": "بيانات المنصة، مواقع التواصل الإجتماعي",
    "system_users": "مستخدمي النظام",
    "system_users_subtitle": "المستخدمين، الصلاحيات، سجل العمليات",
    "profile": "بيانات المستخدم",
    "profile_subtitle": "تعديل بياناتك الشخصية",
    "keywords": "الكلمات الدلالية",
    "keywords_subtitle": "إضافة وتعديل الكلمات الدلالية",
    "password": "كلمة المرور",
    "advance_setting": "اعدادات متقدمة",
    "language": "اللغة",
    "personal_information": "البيانات الشخصية",
    "notifications_setting": "صوت الإشعارات",
    "welcome_user": "أهلا بك",
    "update_personal_information": "تعديل البيانات الشخصية",
    "change_passowrd": "تعديل كلمة المرور",
    "reset_password": "إعادة تعيين كلمة المرور"
  },
  "videos": {
    "table": {
      "profile": "الاسم",
      "section": "المجلد",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "عنوان الفيديو",
      "file": "رابط الفيديو",
      "section": "موقع الفيديو",
      "section_placeholder": "اختر موقع الفيديو"
    },
    "modal": {
      "new": "إضافة فيديو",
      "update": "تعديل فيديو"
    }
  },
  "photos": {
    "table": {
      "profile": "الاسم",
      "section": "المجلد",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "عنوان الصورة",
      "file": "ملف الصورة",
      "section": "موقع الصورة",
      "section_placeholder": "اختر موقع الصورة"
    },
    "modal": {
      "new": "إضافة صورة",
      "update": "تعديل صورة"
    }
  },
  "ads": {
    "title": "الإعلانات المصغرة",
    "table": {
      "profile": "الإعلان",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "الاسم",
      "image": "الصورة"
    },
    "modal": {
      "new": "إضافة إعلان",
      "update": "تعديل إعلان"
    }
  },
  "reports": {
    "table": {
      "profile": "الاسم",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "عنوان التقرير",
      "file": "ملف التقرير",
      "image": "صورة التقرير"
    },
    "modal": {
      "new": "إضافة تقرير",
      "update": "تعديل تقرير"
    }
  },
  "magazines": {
    "table": {
      "profile": "الاسم",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "عنوان المجلة",
      "file": "ملف المجلة",
      "image": "صورة المجلة"
    },
    "modal": {
      "new": "إضافة مجلة",
      "update": "تعديل مجلة"
    }
  },
  "folders": {
    "table": {
      "profile": "الاسم",
      "items_count": "عدد الملفات",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "edit": "تعديل",
      "delete": "حذف"
    },
    "form": {
      "title": "اسم المجلد",
      "file": "صورة المجلد",
      "image": "صورة المجلد"
    },
    "modal": {
      "new": "إضافة مجلد",
      "update": "تعديل مجلد"
    }
  },
  "management": {
    "table": {
      "title": "اسم الإدارة",
      "items_count": "عدد الأعضاء",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "edit": "تعديل",
      "delete": "حذف"
    },
    "form": {
      "title": "اسم الإدارة"
    },
    "modal": {
      "new": "إضافة إدارة",
      "update": "تعديل إدارة"
    }
  },
  "sections": {
    "table": {
      "profile": "الاسم",
      "items_count": "عدد البطاقات",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "edit": "تعديل",
      "delete": "حذف"
    },
    "form": {
      "title": "اسم القسم",
      "file": "صورة القسم",
      "image": "صورة القسم"
    },
    "modal": {
      "new": "إضافة قسم",
      "update": "تعديل قسم"
    }
  },
  "credit-cards": {
    "title": "البطاقات",
    "table": {
      "profile": "الاسم",
      "section": "القسم",
      "description": "الوصف",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "sections": {
      "title": "الاقسام"
    },
    "form": {
      "image": "صورة البطاقة",
      "file": " وثيقة طلب اصدار بطاقة",
      "title": "اسم البطاقة",
      "section": "القسم",
      "section_placeholder": "اختر القسم",
      "description": "الوصف"
    },
    "modal": {
      "new": "إضافة بطاقة",
      "update": "تعديل بطاقة"
    },
    "update": {
      "title": "تعديل بطاقة"
    },
    "create": {
      "title": "إضافة بطاقة"
    }
  },
  "slider": {
    "title": "سلايد شو",
    "table": {
      "profile": "الاسم",
      "slide_title": " عنوان الفقرة",
      "content": "المحتوى",
      "period": "المدة",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "name": "الاسم",
      "title": "عنوان الفقرة",
      "image": "صورة الفقرة",
      "content": "المحتوى",
      "period": "المدة",
      "btn_link": "مكان الانتقال",
      "btn_primary": "نص الزر الاساسي",
      "btn_secondary": "نص الزر الثانوي"
    },
    "update": {
      "title": "تعديل إعلان"
    },
    "create": {
      "title": "إضافة إعلان جديد"
    }
  },
  "about": {
    "title": "عن البنك",
    "table": {
      "profile": "القسم",
      "section_title": " عنوان الفقرة",
      "content": "المحتوى",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "name": "الاسم",
      "title": "عنوان الفقرة",
      "image": "صورة الفقرة",
      "content": "المحتوى"
    },
    "update": {
      "title": "تعديل قسم"
    }
  },
  "home": {
    "title": "محتوى الصفحة الرئيسية",
    "table": {
      "profile": "الاسم",
      "section_title": " عنوان الفقرة",
      "content": "المحتوى",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "name": "الاسم",
      "title": "عنوان الفقرة",
      "image": "صورة الفقرة",
      "content": "المحتوى"
    },
    "update": {
      "title": "تعديل قسم"
    }
  },
  "pages": {
    "title": "سياسة الالتزام",
    "table": {
      "title": "العنوان",
      "content": "المحتوى",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "name": "الاسم",
      "title": "عنوان الفقرة",
      "image": "صورة الفقرة",
      "content": "المحتوى"
    },
    "update": {
      "title": "تعديل قسم"
    }
  },
  "branch": {
    "title": "الفروع",
    "table": {
      "name": "اسم الفرع",
      "address": "عنوان الفرع",
      "governorate": "المحافظة",
      "phone": "رقم الجوال",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "geo_location": "عنوان الفرع على الخريطة",
      "name": "اسم الفرع",
      "address": "عنوان الفرع",
      "gov": "المحافظة",
      "gov_placeholder": "اختر المحافظة",
      "phone": "رقم الجوال"
    },
    "create": {
      "title": "إضافة فرع جديد"
    },
    "update": {
      "title": "تعديل فرع"
    }
  },
  "atm": {
    "title": "شبكة الصراف الالي",
    "table": {
      "name": "اسم الصراف",
      "address": "عنوان الصراف",
      "governorate": "المحافظة",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "geo_location": "عنوان الصراف على الخريطة",
      "name": "اسم الصراف",
      "address": "عنوان الصراف",
      "gov": "المحافظة",
      "gov_placeholder": "اختر المحافظة"
    },
    "create": {
      "title": "إضافة صراف جديد"
    },
    "update": {
      "title": "تعديل صراف"
    }
  },
  "pos": {
    "title": "نقاط البيع",
    "table": {
      "name": "اسم النقطة",
      "address": "عنوان النقطة",
      "governorate": "المحافظة",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "geo_location": "عنوان النقطة على الخريطة",
      "name": "اسم النقطة",
      "address": "عنوان النقطة",
      "gov": "المحافظة",
      "gov_placeholder": "اختر المحافظة"
    },
    "create": {
      "title": "إضافة نقطة جديدة"
    },
    "update": {
      "title": "تعديل نقطة"
    }
  },
  "news": {
    "title": "الاخبار",
    "table": {
      "profile": "العنوان",
      "content": "المحتوى",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "sections": {
      "title": "الاقسام"
    },
    "form": {
      "image": "صورة الخبر",
      "title": "اسم الخبر",
      "content": "المحتوى"
    },
    "update": {
      "title": "تعديل خبر"
    },
    "create": {
      "title": "إضافة خبر"
    }
  },
  "applications": {
    "title": "تطبيقات البنك",
    "table": {
      "profile": "العنوان",
      "description": "المحتوى",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "sections": {
      "title": "الاقسام"
    },
    "form": {
      "image": "صورة التطبيق",
      "file": "ملف التطبيق",
      "title": "اسم التطبيق",
      "content": "المحتوى",
      "app_store_link": "رابط متجر ابل",
      "google_play_link": "رابط متجر جوجل"
    },
    "update": {
      "title": "تعديل بيانات التطبيق"
    },
    "create": {
      "title": "إضافة تطبيق"
    }
  },
  "faq": {
    "title": "الأسئلة الشائعة",
    "table": {
      "question": "محتوى السؤال",
      "answer": "محتوى الجواب",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "question": "محتوى السؤال",
      "answer": "محتوى الجواب"
    },
    "modal": {
      "new": "إضافة سؤال",
      "update": "تعديل سؤال"
    }
  },
  "contacts": {
    "title": "مواقع التواصل الإجتماعي",
    "table": {
      "profile": "المنصة",
      "value": "الرابط",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "icon": "أيقونة المنصة",
      "name": "اسم المنصة",
      "value": "رابط المنصة"
    },
    "modal": {
      "new": "إضافة منصة جديدة",
      "update": "تعديل منصة"
    }
  },
  "staff": {
    "title": "مجلس الإدارة",
    "management": {
      "title": "الإدارات"
    },
    "table": {
      "profile": "الاسم",
      "job": "المنصب",
      "management": "الإدارة",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "name": "اسم العضو",
      "job": "المنصب",
      "management": "الإدارة",
      "management_placeholder": "اختر الإدارة",
      "image": "الصورة"
    },
    "create": {
      "title": "إضافة عضو جديد"
    },
    "update": {
      "title": "تعديل عضو"
    },
    "modal": {
      "new": "إضافة عضو جديد",
      "update": "تعديل عضو"
    }
  },
  "main-services": {
    "title": "الخدمات ",
    "table": {
      "profile": "اسم الخدمة الرئيسية",
      "items_count": "عدد الخدمات",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    }
  },
  "sub-services": {
    "title": "الخدمات ",
    "table": {
      "profile": "الاسم",
      "items_count": "الخدمات الفرعية",
      "content": "المحتوى",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "اسم الخدمة ",
      "content": "المحتوى",
      "image": "الصورة",
      "switch": "عرض تفاصيل الخدمة"
    },
    "modal": {
      "new": "إضافة خدمة جديد",
      "update": "تعديل خدمة"
    }
  },
  "jawaher_extra": {
    "title": "محتوى إضافي ",
    "table": {
      "profile": "العنوان",
      "content": "المحتوى",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "اسم الخدمة ",
      "content": "المحتوى",
      "image": "الصورة"
    },
    "update": {
      "title": "تعديل المحتوى "
    }
  },
  "jawaher_awards": {
    "title": "الجوائز ",
    "table": {
      "profile": "اسم الجائزة",
      "content": "الوصف",
      "count": "عدد الجوائز",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "اسم الجائزة",
      "count": "عدد الجوائز",
      "content": "الوصف",
      "image": "الصورة"
    },
    "modal": {
      "update": "تعديل المحتوى "
    }
  },
  "jawaher_awards_section": {
    "title": "الجوائز ",
    "table": {
      "title": "عنوان الفقرة",
      "content": "الوصف",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "عنوان الفقرة",
      "content": "الوصف"
    },
    "update": {
      "title": "تعديل المحتوى "
    }
  },
  "sub-subservices": {
    "title": "الخدمات ",
    "table": {
      "profile": "الاسم",
      "content": "المحتوى",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "اسم الخدمة ",
      "content": "المحتوى",
      "image": "الصورة",
      "file": "ملف الخدمة"
    },
    "create": {
      "title": "إضافة خدمة جديد"
    },
    "update": {
      "title": "تعديل خدمة"
    }
  },
  "terms": {
    "title": "الشروط والمزايا ",
    "table": {
      "title": "عنوان الفقرة",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "المحتوى"
    },
    "create": {
      "title": "إضافة ميزة جديدة"
    },
    "update": {
      "title": "تعديل الميزة"
    }
  },
  "winners": {
    "title": "الفائزين في جواهر",
    "groups": {
      "title": "تخصيص الاقسام",
      "draws": "السحب",
      "awards": "الجوائز"
    },
    "table": {
      "profile": "الاسم",
      "award": "الجائزة",
      "branch": "الفرع",
      "year": "السنة",
      "draw": "السحب",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "اسم الفائز",
      "award": "الجائزة",
      "award_placeholder": "اختر الجائزة",
      "branch": "الفرع",
      "branch_placeholder": "اختر الفرع",
      "year": "السنة",
      "year_placeholder": "اختر السنة",
      "draw": "السحب",
      "draw_placeholder": "اختر السحب"
    },
    "modal": {
      "new": "إضافة فائز جديد",
      "update": "تعديل الفائز"
    }
  },
  "draws": {
    "title": "السحب",
    "table": {
      "title": "اسم السحب",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "اسم السحب"
    },
    "modal": {
      "create": "إضافة السحب جديد",
      "update": "تعديل السحب"
    }
  },
  "awards": {
    "title": "الجوائز",
    "table": {
      "title": "اسم الجائزة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "اسم الجائزة"
    },
    "modal": {
      "create": "إضافة جائزة جديد",
      "new": "إضافة جائزة جديد",
      "update": "تعديل الجائزة"
    }
  },
  "keywords": {
    "table": {
      "title_ar": "الكلمة الدلالية بالعربي",
      "title_en": "الكلمة الدلالية بالانجليزي",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "form": {
      "title": "الكلمة الدلالية"
    },
    "modal": {
      "new": "إضافة كلمة دلالية جديدة",
      "update": "تعديل الكلمة الدلالية"
    }
  },
  "companies": {
    "company_details": "تفاصيل الشركة",
    "company_update": "تعديل بيانات الشركة",
    "table": {
      "title": "اسم الشركة",
      "total_points": "اجمالي النقاط",
      "spent_points": "النقاط المستهلكة",
      "total_users": "عدد المستخدمين",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "details": "تفاصيل الشركة"
    },
    "statisics": {
      "total_clients": "عدد العملاء",
      "total_users": "عدد المستخدمين",
      "total_points": "إجمالي النقاط",
      "current_points": "النقاط المتبقية",
      "used_point": "النقاط المستهلكة"
    },
    "company_information": "معلومات الشركة",
    "subscriptions": "المشتركين",
    "activate": "تفعيل إمكانية الاشتراك",
    "disable": "إلغاء إمكانية الاشتراك"
  },
  "clients": {
    "client_details": "تفاصيل المستخدم",
    "total_integrations": "اشتراكات",
    "gender": {
      "m": "ذكر",
      "f": "انثى"
    },
    "birth_date": "تاريخ الميلاد",
    "table": {
      "name": "اسم المستخدم",
      "phone": "رقم الهاتف",
      "gender": "النوع",
      "email": "البريد الإلكتروني",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "details": "عرض التفاصيل",
      "send_notification": "إرسال إشعار"
    }
  },
  "client-integrations": {
    "title": "الاشتراكات",
    "table": {
      "profile": "اسم الشركة",
      "total_points": "اجمالي النقاط",
      "spent_points": "النقاط المستهلكة",
      "current_points": "النقاط المتبقية",
      "created_at": "تاريخ الإشتراك",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "show": "عرض سجل العمليات"
    }
  },
  "company-integrations": {
    "title": "الاشتراكات",
    "table": {
      "profile": "اسم المستخدم",
      "total_points": "عدد النقاط",
      "spent_points": "النقاط المستهلكة",
      "current_points": "النقاط المتبقية",
      "created_at": "تاريخ الإشتراك",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "show": "عرض سجل العمليات"
    }
  },
  "points": {
    "title": "سجل العمليات",
    "type_id": {
      "0": "نقاط مستهلكة",
      "1": "نقاط مكتسبة"
    },
    "table": {
      "type_id": "نوع العملية",
      "total_points": "إجمالي النقاط",
      "created_at": "تاريخ العملية"
    }
  },
  "integrations": {
    "title": "الاشتراكات"
  },
  "advertisements": {
    "create_new": "إضافة إعلان جديد",
    "table": {
      "profile": "الإعلان",
      "created_at": "تاريخ الإضافة",
      "status": "تفعيل/ إيقاف",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "alter": "تعديل الإعلان",
      "delete": "حذف الإعلان"
    }
  },
  "notifications": {
    "send_new_notification": "إضافة إشعار جديد",
    "table": {
      "title": "اسم الإشعار",
      "profile": "عنوان الإشعار",
      "content": "محتوى الإشعار",
      "target": "الفئة المستهدفة",
      "company_name": "التوجه",
      "created_at": "تاريخ الإضافة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "resend": "إعادة الإرسال"
    },
    "targets": "الفئة المستهدفة",
    "add_target": "إضافة فئة إستهداف",
    "client": "عميل",
    "all_users": "جميع المستخدمين",
    "specific_user": "مستخدمين محددين",
    "filtered_users": "فئات أخرى",
    "by_age": "حسب العمر",
    "gender": "الجنس",
    "all": "الكل",
    "specific": "مخصص",
    "male_only": "ذكور فقط",
    "female_only": "نساء فقط",
    "specific_age": "العمر",
    "from_to": "من -- الى --",
    "date_error": "يجب ان يكون حقل البدايه اكبر من النهاية"
  },
  "system-roles": {
    "create_new": "إضافة صلاحية جديدة",
    "update_role": "تعديل صلاحية",
    "delete": "حذف الصلاحية",
    "table": {
      "name": "مسمى الصلاحية",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "view": "عرض التفاصيل"
    }
  },
  "news-letters": {
    "table": {
      "profile": "العنوان",
      "created_at": "تاريخ الإضافة"
    }
  },
  "permissions": {
    "sections": {
      "dashboard": "الرئيسية",
      "companies": "الشركات",
      "clients": "المستخدمين",
      "ads": "الإعلانات",
      "notifications": "الإشعارات",
      "settings": "الاعدادات",
      "platform": "إعدادات المنصة",
      "roles": "الصلاحيات",
      "admin_users": "مستخدمي النظام",
      "user_profile": "إعدادات الحساب الشخصي",
      "staff": "مجلس الإدارة",
      "social": "بيانات التوصل",
      "sliders": "سلايد شو",
      "site-content-home": "بيانات الصفحة الرئيسية",
      "site-content-about": "عن البنك",
      "services": "الخدمات",
      "news": "الأخبار",
      "pages": "سياسة الالتزام",
      "logs": "سجل العمليات",
      "location_(pos)": "الموقع (POS)",
      "location_(branch)": "الموقع (فرع)",
      "location_(atm)": "الموقع (ATM)",
      "library_(photos)": "المركز الإعلامي (صور)",
      "library_(videos)": "المركز الإعلامي (فيديو)",
      "library_(reports)": "المركز الإعلامي (تقارير)",
      "library_(magazines)": "المركز الإعلامي (مجلات)",
      "contacts": "بيانات التواصل",
      "faqs": "الأسئلة الشائعة",
      "home": "بيانات الصفحة الرئيسية",
      "cards": "البطاقات",
      "jawaher_faqs": "[جواهر] الأسئلة الشائعة",
      "jawaher_sliders": "[جواهر] سلايد شو",
      "jawaher_videos": "[جواهر] فيديوهات",
      "jawaher_photos": "[جواهر] صور",
      "jawaher-about": "[جواهر] عن البنك",
      "jawaher-award": "[جواهر] الجوائز",
      "jawaher-winners": "[جواهر] الفائزين",
      "jawaher-extra": "[جواهر] بيانات إضافية",
      "jawaher-terms": "[جواهر] الشروط والأحكام",
      "jawaher-winners-groups": "[جواهر] اقسام الفائزين",
      "jawaher-applications": "[جواهر] تطبيقات البنك",
      "applications": "تطبيقات البنك",
      "messages": "الدعم الفني",
      "seo_keywords": "الكلمات الدلالية",
      "support": "الدعم الفني - الرسائل الاخبارية"
    },
    "read_news_letters": "عرض المشتركين في الرسائل الاخبارية",
    "read_seo_keywords": "عرض الكلمات الدلالية",
    "edit_seo_keywords": "تحرير الكلمات الدلالية",
    "add_seo_keywords": "إضافة الكلمات الدلالية",
    "delete_seo_keywords": "حذف الكلمات الدلالية",
    "read_messages": "عرض الرسائل",
    "delete_messages": "حذف الرسائل",
    "read_applications": "عرض تطبيقات البنك",
    "edit_applications": "تحرير تطبيقات البنك",
    "add_applications": "إضافة تطبيقات البنك",
    "delete_applications": "حذف تطبيقات البنك",
    "read_contacts": "عرض بيانات التواصل",
    "edit_contacts": "تحرير بيانات التواصل",
    "read_pages": "عرض سياسة الالتزام",
    "edit_pages": "تحرير سياسة الالتزام",
    "read_faqs": "عرض الأسئلة الشائعة",
    "edit_faqs": "تحرير الأسئلة الشائعة",
    "add_faqs": "إضافة الأسئلة الشائعة",
    "delete_faqs": "حذف الأسئلة الشائعة",
    "read_cards": "عرض البطاقات",
    "edit_cards": "تحرير البطاقات",
    "add_cards": "إضافة البطاقات",
    "delete_cards": "حذف البطاقات",
    "read_card_sections": "عرض أقسام البطاقات",
    "edit_card_sections": "تحرير أقسام البطاقات",
    "add_card_sections": "إضافة أقسام البطاقات",
    "delete_card_sections": "حذف أقسام البطاقات",
    "read_home": "عرض بيانات الصفحة الرئيسية",
    "read_jawaher_faqs": "[جواهر] عرض الأسئلة الشائعة",
    "edit_jawaher_faqs": "[جواهر] تحرير الأسئلة الشائعة",
    "add_jawaher_faqs": "[جواهر] إضافة الأسئلة الشائعة",
    "delete_jawaher_faqs": "[جواهر] حذف الأسئلة الشائعة",
    "read_jawaher_sliders": "[جواهر] عرض سلايد شو",
    "edit_jawaher_sliders": "[جواهر] تحرير سلايد شو",
    "add_jawaher_sliders": "[جواهر] إضافة سلايد شو",
    "delete_jawaher_sliders": "[جواهر] حذف سلايد شو",

    "read_jawaher_videos": "[جواهر] عرض الفيديوهات",
    "edit_jawaher_videos": "[جواهر] تحرير الفيديوهات",
    "add_jawaher_videos": "[جواهر] إضافة الفيديوهات",
    "delete_jawaher_videos": "[جواهر] حذف الفيديوهات",
    "read_jawaher_videos_folder": "[جواهر] عرض مجلدات الفيديوهات",
    "edit_jawaher_videos_folder": "[جواهر] تحرير مجلدات الفيديوهات",
    "add_jawaher_videos_folder": "[جواهر] إضافة مجلدات الفيديوهات",
    "delete_jawaher_videos_folder": "[جواهر] حذف مجلدات الفيديوهات",

    "read_jawaher_photos": "[جواهر] عرض الصور",
    "edit_jawaher_photos": "[جواهر] تحرير الصور",
    "add_jawaher_photos": "[جواهر] إضافة الصور",
    "delete_jawaher_photos": "[جواهر] حذف الصور",
    "read_jawaher_photos_folder": "[جواهر] عرض مجلدات الصور",
    "edit_jawaher_photos_folder": "[جواهر] تحرير مجلدات الصور",
    "add_jawaher_photos_folder": "[جواهر] إضافة مجلدات الصور",
    "delete_jawaher_photos_folder": "[جواهر] حذف مجلدات الصور",



    "read_jawaher_applications": "[جواهر] عرض تطبيقات جواهر",
    "edit_jawaher_applications": "[جواهر] تحرير تطبيقات جواهر",
    "add_jawaher_applications": "[جواهر] إضافة تطبيقات جواهر",
    "delete_jawaher_applications": "[جواهر] حذف تطبيقات جواهر",
    "read_jawaher_about_sections": "[جواهر] عرض بيانات صفحة عن البنك",
    "edit_jawaher_about_sections": "[جواهر] تحرير بيانات صفحة عن البنك",
    "read_jawaher_award_sections": "[جواهر] عرض الجوائز",
    "edit_jawaher_award_sections": "[جواهر] تحرير الجوائز",
    "read_jawaher_winners": "[جواهر] عرض الفائزين",
    "edit_jawaher_winners": "[جواهر] تحرير الفائزين",
    "add_jawaher_winners": "[جواهر] إضافة الفائزين",
    "delete_jawaher_winners": "[جواهر] حذف الفائزين",
    "read_jawaher_extra_sections": "[جواهر] عرض بيانات إضافية",
    "edit_jawaher_extra_sections": "[جواهر] تحرير بيانات إضافية",
    "read_jawaher_terms": "[جواهر] عرض الشروط والأحكام",
    "edit_jawaher_terms": "[جواهر] تحرير الشروط والأحكام",
    "add_jawaher_terms": "[جواهر] إضافة الشروط والأحكام",
    "delete_jawaher_terms": "[جواهر] حذف الشروط والأحكام",
    "read_jawaher_winners_groups": "[جواهر] عرض اقسام الفائزين",
    "edit_jawaher_winners_groups": "[جواهر] تحرير اقسام الفائزين",
    "add_jawaher_winners_groups": "[جواهر] إضافة اقسام الفائزين",
    "delete_jawaher_winners_groups": "[جواهر] حذف اقسام الفائزين",
    "read_home_sections": "عرض بيانات الصفحة الرئيسية",
    "edit_home_sections": "تحرير بيانات الصفحة الرئيسية",
    "read_about_sections": "عرض بيانات صفحة عن البنك",
    "edit_about_sections": "تحرير بيانات صفحة عن البنك",
    "read_location_pos": "عرض موقع البنك (POS)",
    "edit_location_pos": "تحرير موقع البنك (POS)",
    "add_location_pos": "إضافة موقع البنك (POS)",
    "delete_location_pos": "حذف موقع البنك (POS)",
    "read_location_branch": "عرض موقع البنك (فرع)",
    "edit_location_branch": "تحرير موقع البنك (فرع)",
    "add_location_branch": "إضافة موقع البنك (فرع)",
    "delete_location_branch": "حذف موقع البنك (فرع)",
    "read_location_atm": "عرض موقع البنك (ATM)",
    "edit_location_atm": "تحرير موقع البنك (ATM)",
    "add_location_atm": "إضافة موقع البنك (ATM)",
    "delete_location_atm": "حذف موقع البنك (ATM)",
    "read_library_photos": "عرض مكتبة الصور",
    "edit_library_photos": "تحرير مكتبة الصور",
    "add_library_photos": "إضافة صورة",
    "delete_library_photos": "حذف صورة",
    "read_library_folder_photos": "عرض مجلدات مكتبة الصور",
    "edit_library_folder_photos": "تحرير مجلدات مكتبة الصور",
    "add_library_folder_photos": "إضافة مجلد",
    "delete_library_folder_photos": "حذف مجلد",
    "read_library_videos": "عرض مكتبة الفيديوهات",
    "edit_library_videos": "تحرير مكتبة الفيديوهات",
    "add_library_videos": "إضافة فيديو",
    "delete_library_videos": "حذف فيديو",
    "read_library_folder_videos": "عرض مجلدات مكتبة الفيديوهات",
    "edit_library_folder_videos": "تحرير مجلدات مكتبة الفيديوهات",
    "add_library_folder_videos": "إضافة مجلد",
    "delete_library_folder_videos": "حذف مجلد",
    "read_library_reports": "عرض مكتبة التقارير",
    "edit_library_reports": "تحرير مكتبة التقارير",
    "add_library_reports": "إضافة تقرير",
    "delete_library_reports": "حذف تقرير",
    "read_library_magazines": "عرض مكتبة المجلات",
    "edit_library_magazines": "تحرير مكتبة المجلات",
    "add_library_magazines": "إضافة مجلة",
    "delete_library_magazines": "حذف مجلة",
    "read_services": "عرض الخدمات",
    "edit_services": "تحرير الخدمات",
    "add_services": "إضافة خدمة",
    "delete_services": "حذف خدمة",
    "read_news": "عرض الأخبار",
    "edit_news": "تحرير الأخبار",
    "add_news": "إضافة خبر",
    "delete_news": "حذف خبر",
    "read_logs": "عرض سجل العمليات",
    "add_sliders": "إضافة سلايد شو",
    "read_sliders": "عرض سلايد شو",
    "edit_sliders": "تحرير سلايد شو",
    "delete_sliders": "حذف سلايد شو",
    "add_staff": "إضافة عضو",
    "read_staff": "عرض عضو",
    "edit_staff": "تحرير عضو",
    "delete_staff": "حذف عضو",
    "add_staff_sections": "اضافة إدارة",
    "read_staff_sections": "عرض إدارة",
    "edit_staff_sections": "تحرير إدارة",
    "delete_staff_sections": "حذف إدارة",
    "read_social": "عرض بيانات التوصل",
    "edit_social": "تحرير بيانات التوصل",
    "read_dashboard": "عرض الرئيسية",
    "read_companies": "عرض الشركات",
    "edit_companies": "تحرير الشركات",
    "delete_companies": "حذف الشركات",
    "read_clients": "عرض المستخدمين",
    "edit_clients": "تحرير المستخدمين",
    "delete_clients": "حذف المستخدمين",
    "read_ads": "عرض الإعلانات",
    "add_ads": "إضافة الإعلانات",
    "edit_ads": "تحرير الإعلانات",
    "delete_ads": "حذف الإعلانات",
    "read_notifications": "عرض الإشعارات",
    "send_notifications": "إرسال الإشعارات",
    "edit_notifications": "تحرير الإشعارات",
    "delete_notifications": "حذف الإشعارات",
    "read_platform": "عرض معلومات المنصة",
    "edit_platform": "تحرير معلومات المنصة",
    "read_roles": "عرض الصلاحيات",
    "add_roles": "إضافة الصلاحيات",
    "edit_roles": "تحرير الصلاحيات",
    "delete_roles": "حذف الصلاحيات",
    "read_admin_users": "عرض مستخدمي النظام",
    "add_admin_users": "إضافة مستخدمي النظام",
    "edit_admin_users": "تحرير مستخدمي النظام",
    "delete_admin_users": "حذف مستخدمي النظام",
    "read_admin_users_logs": "عرض سجل مستخدمي النظام",
    "reset_password_admin_users": "إعادة تعيين كلمة مرور مستخدمي النظام"
  },
  "system-users": {
    "create_new": "إضافة مستخدم جديد",
    "view_logs": "عرض سجل العمليات",
    "roles_management": "إدارة الصلاحيات",
    "logs": "سجل العمليات",
    "resend_password": "إعادة إرسال كلمة المرور",
    "delete": "حذف المستخدم",
    "table": {
      "profile": "اسم المستخدم",
      "email": "البريد الإلكتروني",
      "role": "المسمى",
      "last_activity": "اخر نشاط",
      "created_at": "تاريخ الإضافة",
      "status": "الحالة",
      "actions": "الاجراءات"
    },
    "table_actions": {
      "view": "عرض التفاصيل"
    }
  },
  "logs": {
    "table": {
      "user": "اسم المستخدم",
      "title": "اسم العملية",
      "content": "تفاصيل العملية",
      "created_date_time": "تاريخ العملية"
    }
  },
  "dashboard": {
    "messages": "الإيميلات الجديدة",
    "newsletters": "طلبات النشرة البريدية الجديدة",
    "view_all": "عرض الكل",
    "statisics": {
      "title": "الإحصائيات",
      "total_clients": "عدد العملاء",
      "total_companies": "إجمالي الشركات",
      "total_points": "إجمالي النقاط",
      "current_points": "النقاط المتبقية",
      "used_point": "النقاط المستهلكة"
    },
    "points_collecting_avarage": "معدل جمع النقاط",
    "last_7_days": "آخر 7 ايام",
    "last_30_days": "آخر 30 يوم",
    "last_12_months": "آخر 12 شهر",
    "clients_gender": "نوعية المستخدمين",
    "males": "ذكور",
    "females": "إناث",
    "highest_company_avarage": "اكثر الشركات جمع للنقاط",
    "point": "نقطة",
    "client_joining_avarage": "معدل إنضمام المستخدمين",
    "request": "طلب",
    "resent_users": "احدث المستخدمين",
    "user": "مستخدم",
    "aquire": "النقاط المكتسبة",
    "spent": "النقاط المستهلكة"
  },
  "forms_required": "هذا الحقل مطلوب",
  "inputs": {
    "title_ar": "الاسم العربي",
    "title_en": "الاسم الانجليزي",
    "title": "الاسم",
    "name": "الاسم",
    "email": "البريد الإلكتروني",
    "phone": "رقم الهاتف",
    "password": "كلمة المرور",
    "password_confirmation": "تأكيد كلمة المرور",
    "status": "الحالة",
    "active": "مفعل",
    "content_ar": "المحتوى بالعربي",
    "content_en": "المحتوى بالانجليزي",
    "image": "الصورة",
    "images": "الصور",
    "video": "الفيديو",
    "file": "الملف"
  }
}