{
  "header": {
    "notifications": "الإشعارات",
    "show_all_notifications": "عرض كل الإشعارات",
    "change_language": "تغيير اللغة"
  },
  "sidebar": {
    "home": "الرئيسية",
    "companies": "الشركات",
    "clients": "المستخدمين",
    "advertisements": "الإعلانات",
    "notifications": "الإشعارات",
    "settings": "الاعدادات",
    "platform": "إعدادات المنصة",
    "system-roles": "الصلاحيات",
    "system-users": "مستخدمي النظام",
    "user_profile": "إعدادات الحساب الشخصي",
    "site-content": "محتوى الموقع",
    "library": "المركز الإعلامي",
    "support": "الدعم الفني",
    "credit-cards": "البطاقات",
    "locations": "تواجد البنك",
    "services": "الخدمات",
    "jawaher": "جواهر التجاري"
  },
  "footer": {
    "all_rights_reserved": "جميع الحقوق محفوظة لمنصة YCB",
    "made_by": "صنع في",
    "wameed": "وميض"
  }
}