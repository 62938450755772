{
  "seo": {
    "title": "Enable SEO mode for the current page",
    "description": "You can customize the SEO of this page individually",
    "btn": "customize settings",
    "form": {
      "title": "page title",
      "description": "Description of the page",
      "keywords": "Tags",
      "keywords_placeholder": "Select appropriate keywords for the page",
    }
  }
}