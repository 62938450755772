{
  "site-content": {
    "slider": {
      "title": "Slideshow",
      "description": "Show ads on the home page"
    },
    "info": {
      "title": "About the Bank",
      "description": "goals, visions, terms, policies, contact information"
    },
    "home": {
      "title": "Home Page Content",
      "description": "Main Page Content Details"
    },
    "news": {
      "title": "News",
      "description": "Display news on the home page"
    },
    "management": {
      "title": "Board of Directors",
      "description": "Members and Staff"
    },
    "faq": {
      "title": "Frequently Asked Questions",
      "description": "FAQ Data"
    },
    "policy": {
      "title": "Compliance Policy",
      "description": "Legal text matters"
    },
    "social": {
      "title": "Social Networking Sites",
      "description": "Facebook, Twitter, Instagram"
    },
    "application": {
      "title": "Bank Applications",
      "description": "Show apps on the home page"
    },
    "ads": {
      "title": "Thumbnail Ads",
      "description": "Home Page Thumbnails Section"
    }
  },
  "extra": {
    "title": "Home Page Content",
    "update": {
      "title": "Update Data"
    }
  }
}