{
  "support": {
    "empty": "لا يوجد بريد",
    "news_letter": "قائمة عنواين النشرة البريدية",
    "mark-read": "التمييز كمقروءة",
    "reply": "أكتب ردك هنا",
    "side": {
      "trash": "سلة المهملات",
      "draft": "المسودات",
      "star": "المميزة",
      "sent-mail": "البريد المرسل",
      "inbox": "البريد الوارد",
      "all": "كل البريد",
      "folders": "نوع الموضوع",
      "all_folders": "الكل"
    },
    "table": {
      "name": "الاسم",
      "subject": "الموضوع",
      "message": "الرسالة",
      "created_at": "تاريخ الإنشاء",
      "message_type": "نوع الرسالة"
    }
  }
}

