{
  "jawaher": {
    "slider": {
      "title": "سلايد شو",
      "description": "عرض الإعلانات في الصفحة الرئيسية"
    },
    "info": {
      "title": "عن جواهر التجاري",
      "description": "تفاصيل المحتوى للصفحة الرئيسية"
    },
    "extra": {
      "title": "محتوى إضافي",
      "description": "السحوبات, محتوى حواء"
    },
    "winners": {
      "title": "الفائزين في جواهر",
      "description": "أسماء  الفائزين في مسابقات البنك"
    },
    "conditions": {
      "title": "الشروط والمزايا",
      "description": "شروط الاشتراك  في جواهر"
    },
    "faq": {
      "title": "الأسئلة الشائعة",
      "description": "بيانات الأسئلة الشائعة"
    },
    "awards": {
      "title": "الجوائز",
      "section": "محتوى القسم",
      "description": "السيارات، الشقق، عشرات الجوائز"
    },
    "video": {
      "title": "الفيديوهات",
      "description": "محتوى الفيديوهات"
    },
    "photo": {
      "title": "الصور",
      "description": "محتوى الصور"
    },
    "folders": {
      "title": "المجلدات",
      "description": "محتوى المجلدات"
    },
    "application": {
      "title": "التطبيقات",
      "description": "تطبيقات جواهر"
    }
  }
}

