{
  "library": {
    "video": {
      "title": "Videos",
      "description": "Show ads on the home page"
    },
    "photo": {
      "title": "images",
      "description": "goals, visions, terms, policies, contact information"
    },
    "report": {
      "title": "Annual Reports",
      "description": "Bank Branches Details"
    },
    "magazine": {
      "title": "Asda'a Magazine",
      "description": "FAQ Data"
    },
    "folders": {
      "title": "folders"
    }
  },
  "locations": {
    "branches": {
      "title": "Branches",
      "description": "The locations of bank branches"
    },
    "atm": {
      "title": "ATM Network",
      "description": "ATM locations"
    },
    "pos": {
      "title": "POS",
      "description": "POS locations"
    }
  }
}