{
  "jawaher": {
    "slider": {
      "title": "Slideshow",
      "description": "Show ads on the home page"
    },
    "info": {
      "title": "About Jawaher Trading",
      "description": "Main Page Content Details"
    },
    "extra": {
      "title": "Extra Content",
      "description": "Raffles, Eve Content"
    },
    "winners": {
      "title": "Jawaher Winners",
      "description": "The names of the winners of the bank's competitions"
    },
    "conditions": {
      "title": "Terms and Benefits",
      "description": "Jawaher Subscription Terms"
    },
    "faq": {
      "title": "Frequently Asked Questions",
      "description": "FAQ Data"
    },
    "awards": {
      "title": "Awards",
      "section": "section content",
      "description": "cars, apartments, dozens of prizes"
    },
    "video": {
      "title": "Videos",
      "description": "Videos of the bank"
    },
    "photo": {
      "title": "Photos",
      "description": "Photos of the bank"
    },
    "folders": {
      "title": "Folders",
      "description": "Folders of the bank"
    },
    "application": {
      "title": "Application",
      "description": "Application of the Jawaher"
    },
  }
}