{
  "library": {
    "video": {
      "title": "الفيديوهات",
      "description": "عرض الإعلانات في الصفحة الرئيسية"
    },
    "photo": {
      "title": "الصور",
      "description": "الأهداف , الرؤى , الشروط , السياسات, بيانات التواصل"
    },
    "report": {
      "title": "التقارير السنوية",
      "description": "بيانات فروع البنك"
    },
    "magazine": {
      "title": "مجلة أصداء",
      "description": "بيانات الأسئلة الشائعة"
    },
    "folders": {
      "title": "المجلدات"
    }
  },
  "locations": {
    "branches": {
      "title": "الفروع",
      "description": "أماكن تواجد فروع البنك"
    },
    "atm": {
      "title": "شبكة الصراف الالي",
      "description": "أماكن تواجد أجهزة الصراف الآلي"
    },
    "pos": {
      "title": "نقاط البيع POS",
      "description": "أماكن تواجد نقاط البيع"
    }
  }
}

