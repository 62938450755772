{
  "common": {
    "hover_image": "الصورة عند الهوفر",
    "no_results": "لا يوجد نتائج",
    "number": "العدد",
    "uploadImage": " اسحب الصورة إلى هنا أو إضغط هنا لإضافة الصورة",
    "images": "الصور",
    "ar": "العربية",
    "en": "Engilsh",
    "default": "الافتراضي",
    "new": "جديد",
    "order_by": "ترتيب حسب",
    "newest": "الاحدث اولا",
    "older": "الاقدم اولا",
    "alphabetical": "ابجديا أ-ي",
    "show_results": "إظهار النتائج من",
    "show_details": "عرض التفاصيل",
    "filter_data": "فلترة العناصر",
    "search": "ابحث عن البيانات",
    "back": "رجوع",
    "main_information": "البيانات الأساسية",
    "social_information": "مواقع التواصل الإجتماعي",
    "upload": "إضافة",
    "optional": "إختياري",
    "folders_management": "إدارة المجلدات",
    "order": "ترتيب",
    "ok": "موافق",
    "cancel": "إلغاء",
    "upload_file": "إضافة ملف",
    "import_excel": "إستيراد من ملف إكسل",
    "export_excel": "تصدير إلى ملف إكسل"
  },
  "actions": {
    "cancel": "الغاء",
    "send": "ارسال",
    "save": "حفظ",
    "add": "إضافة",
    "save_updates": "حفظ التعديلات",
    "show_all": "عرض الكل",
    "resend": "إعادة الإرسال",
    "reset": "إعادة تعيين",
    "apply": "تطبيق",
    "confirm": "تاكيد",
    "delete": "حذف",
    "edit": "تعديل",
    "view": "عرض"
  },
  "no_data": {
    "no_items": "لا يوجد أي عناصر",
    "no_item_message": "المعذرة لم تقم بإضافة أي عناصر حتى الان",
    "no_data": "لا توجد أي بيانات",
    "no_data_message": "لم يتم تقدم أي بيانات حتى الان",
    "page_notfound": "عذراً ،، هذه الصفحة غير متوفرة",
    "page_notfound_message": " المعذرة، هذه الصفحة غير موجودة، قد تكون حذفت، أو نقلت لمكان جديد.",
    "backhome": "العودة للرئيسية",
    "internet_connection": "هناك مشكلة في الإتصال",
    "inernet_connection_message": "يرجى التحقق من اتصالك في الإنترنت و إعادة تحديث الصفحة",
    "refresh": "تحديث",
    "no_notifications": "لا يوجد أي اشعارات",
    "no_notifications_message": "المعذرة لم يتم إرسال أي اشعارات حتى الان",
    "no_more_results": "لا يوجد بيانات اخرى!",
    "no_permissions": "لا توجد لديك صلاحية",
    "no_permissions_message": "المعذرة لا توجد لديك صلاحية لعرض المحتوى"
  },
  "forms": {
    "search": {
      "label": "ابحث"
    },
    "phone_number": {
      "label": "رقم الهاتف"
    },
    "phone": {
      "label": "رقم التواصل"
    },
    "email": {
      "label": "البريد الإلكتروني"
    },
    "password": {
      "label": "كلمة المرور"
    },
    "password_confirmation": {
      "label": "تأكيد كلمة المرور"
    },
    "new_password": {
      "label": "كلمة المرور الجديدة"
    },
    "old_password": {
      "label": "كلمة المرور الحالية"
    },
    "confirm_new_password": {
      "label": "تأكيد كلمة المرور الجديدة"
    },
    "file": {
      "placeholder": "إرفاق ملف"
    },
    "company_logo": {
      "label": "شعار الشركة",
      "placeholder": "إرفاق صور"
    },
    "company_name": {
      "label": "اسم الشركة"
    },
    "company_description": {
      "label": "وصف الشركة"
    },
    "point_collection_ways": {
      "label": "طرق الحصول على النقاط"
    },
    "point_use_instraction": {
      "label": "طرق استخدام النقاط"
    },
    "notification_name": {
      "label": "إسم الإشعار"
    },
    "notification_title": {
      "label": "عنوان الإشعار"
    },
    "notification_image": {
      "label": "صورة الإشعار"
    },
    "redirect": {
      "label": "التوجيه",
      "placeholder": "تفاصيل الشركة"
    },
    "notification_content": {
      "label": "محتوى الإشعار"
    },
    "ads_title": {
      "label": "اسم الاعلان"
    },
    "ads_image": {
      "label": "صورة الاعلان"
    },
    "platform_logo": {
      "label": "شعار المنصة"
    },
    "platform_name": {
      "label": "اسم المنصة"
    },
    "platform_description": {
      "label": "وصف المنصة"
    },
    "facebook": {
      "label": "فيسبوك"
    },
    "instagram": {
      "label": "إنستجرام"
    },
    "twitter": {
      "label": "تويتر"
    },
    "youtube": {
      "label": "يوتيوب"
    },
    "telegram": {
      "label": "تليجرام"
    },
    "linkedIn": {
      "label": "لينكد إن"
    },
    "role": {
      "label": "المسمى",
      "placeholder": "حدد مسمى الصلاحية"
    },
    "role_name": {
      "label": "الاسم",
      "placeholder": "اكتب اسم الصلاحية"
    },
    "user_name": {
      "label": "اسم المستخدم"
    },
    "profile_image": {
      "label": "الصورة الشخصية"
    },
    "profile_images": {
      "label": "صور البروفايل",
      "placeholder": "إرفاق صور"
    },
    "full_name": {
      "label": "الاسم الكامل"
    },
    "message_title": {
      "label": "عنوان الرسالة"
    },
    "message_content": {
      "label": "نص الرسالة"
    },
    "contact_email": {
      "label": "بريد التواصل"
    },
    "support_email": {
      "label": "بريد الدعم"
    },
    "verification_code": {
      "label": "رمز التحقق"
    },
    "other_phone_number": {
      "label": "رقم هاتف اخر"
    }
  },
  "modal": {
    "logout": "أنت على وشك تسجيل الخروج",
    "logout_btn": "تسجيل الخروج",
    "logout_message": "عند تسجيل خروجك لن تتمكن من الدخول للوحة التحكم إلا بكلمة المرور , هل أنت متأكد من ذلك",
    "confirm_updating_profile": "هل تريد تحديث بياناتك",
    "confirm_updating_profile_message": "سيتم تغيير بياناتك للمدخلات الجديدة التي قمت بإضافتها",
    "delete_title_companies": "أنت على وشك عمل تهيئة كلية للشركه",
    "delete_subtitle_companies": "التهيئة الكليه تلغي ربط المستخدمين الحاليين ولا تغلي امكانية الاشتراك بالشركه  ",
    "stop_title_companies": "أنت على وشك الغاء إمكانية إلاشتراك",
    "stop_subtitle_companies": "الغاء إمكانية إلاشتراك تسمح للمستخدمين الحاليين بالوصول لكن لا تسمح للمستخدين الجدد بالإنضمام",
    "delete_title_clients": "حذف المستخدم",
    "delete_subtitle_clients": "أنت على وشك حذف المستخدم ,سيؤدي ذلك إلى حذف المستخدم بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_advertisements": "أنت على وشك حذ ف هذا الإعلان",
    "delete_subtitle_advertisements": "أنت على وشك حذف الإعلان ,سيؤدي ذلك إلى حذف الإعلان بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_system_roles": "أنت على وشك حذ ف هذه الصلاحية",
    "delete_subtitle_system_roles": "أنت على وشك حذف الصلاحية ,سيؤدي ذلك إلى حذف الصلاحية بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_system_users": "أنت على وشك حذف هذا المستخدم",
    "delete_subtitle_system_users": "أنت على وشك حذف المستخدم ,سيؤدي ذلك إلى حذف المستخدم بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "send_notification": "إرسال الإشعار",
    "send_notification_subtitle": "عند الموافقه سيتم ارسال الاشعار لجميع المستخدمين ولن تستطيع التراجع عن هذا الاشعار",
    "delete_title_videos": "أنت على وشك حذف هذا الفيديو",
    "delete_subtitle_videos": "أنت على وشك حذف الفيديو ,سيؤدي ذلك إلى حذف الفيديو بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_folders": "أنت على وشك حذف هذا المجلد",
    "delete_subtitle_folders": "أنت على وشك حذف المجلد ,سيؤدي ذلك إلى حذف المجلد بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_photos": "أنت على وشك حذف هذه الصورة",
    "delete_subtitle_photos": "أنت على وشك حذف الصورة ,سيؤدي ذلك إلى حذف الصورة بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_reports": "أنت على وشك حذف هذا التقرير",
    "delete_subtitle_reports": "أنت على وشك حذف التقرير ,سيؤدي ذلك إلى حذف التقرير بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_magazines": "أنت على وشك حذف هذه المجلة",
    "delete_subtitle_magazines": "أنت على وشك حذف المجلة ,سيؤدي ذلك إلى حذف المجلة بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_sections": "أنت على وشك حذف هذا القسم",
    "delete_subtitle_sections": "أنت على وشك حذف القسم ,سيؤدي ذلك إلى حذف القسم بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_credit_cards": "أنت على وشك حذف هذه البطاقة",
    "delete_subtitle_credit_cards": "أنت على وشك حذف البطاقة ,سيؤدي ذلك إلى حذف البطاقة بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_news": "أنت على وشك حذف هذا الخبر",
    "delete_subtitle_news": "أنت على وشك حذف الخبر ,سيؤدي ذلك إلى حذف الخبر بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_faq": "أنت على وشك حذف هذا السؤال",
    "delete_subtitle_faq": "أنت على وشك حذف السؤال ,سيؤدي ذلك إلى حذف السؤال بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_slider": "أنت على وشك حذف هذا الاعلان",
    "delete_subtitle_slider": "أنت على وشك حذف الاعلان ,سيؤدي ذلك إلى حذف الاعلان بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_branch": "أنت على وشك حذف هذا الفرع",
    "delete_subtitle_branch": "أنت على وشك حذف الفرع ,سيؤدي ذلك إلى حذف الفرع بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_atm": "أنت على وشك حذف هذا الصراف",
    "delete_subtitle_atm": "أنت على وشك حذف الصراف ,سيؤدي ذلك إلى حذف الصراف بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_pos": "أنت على وشك حذف نقطة البيع",
    "delete_subtitle_pos": "أنت على وشك حذف نقطة البيع ,سيؤدي ذلك إلى حذف نقطة البيع بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_management": "أنت على وشك حذف هذا الادارة",
    "delete_subtitle_management": "أنت على وشك حذف الادارة ,سيؤدي ذلك إلى حذف الادارة بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_staff": "أنت على وشك حذف هذا الموظف",
    "delete_subtitle_staff": "أنت على وشك حذف الموظف ,سيؤدي ذلك إلى حذف الموظف بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_sub-services": "أنت على وشك حذف هذه الخدمة الفرعية",
    "delete_subtitle_sub-services": "أنت على وشك حذف الخدمة الفرعية ,سيؤدي ذلك إلى حذف الخدمة الفرعية بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_sub-subservices": "أنت على وشك حذف هذه الخدمة الفرعية",
    "delete_subtitle_sub-subservices": "أنت على وشك حذف الخدمة الفرعية ,سيؤدي ذلك إلى حذف الخدمة الفرعية بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_terms": "أنت على وشك حذف هذه الشروط والمزايا",
    "delete_subtitle_terms": "أنت على وشك حذف الشروط والمزايا ,سيؤدي ذلك إلى حذف الشروط والأحكام بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_awards": "أنت على وشك حذف هذه الجوائز",
    "delete_subtitle_awards": "أنت على وشك حذف الجوائز ,سيؤدي ذلك إلى حذف الجوائز بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_draws": "أنت على وشك حذف هذه السحوبات",
    "delete_subtitle_draws": "أنت على وشك حذف السحوبات ,سيؤدي ذلك إلى حذف السحوبات بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_winners": "أنت على وشك حذف هذا الفائز",
    "delete_subtitle_winners": "أنت على وشك حذف الفائز ,سيؤدي ذلك إلى حذف الفائز بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_message": "أنت على وشك حذف هذه الرسالة",
    "delete_subtitle_message": "أنت على وشك حذف الرسالة ,سيؤدي ذلك إلى حذف الرسالة بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_messages": "أنت على وشك حذف هذه الرسائل",
    "delete_subtitle_messages": "أنت على وشك حذف الرسائل ,سيؤدي ذلك إلى حذف الرسائل بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_keywords": "أنت على وشك حذف هذه الكلمة المفتاحية",
    "delete_subtitle_keywords": "أنت على وشك حذف الكلمة المفتاحية ,سيؤدي ذلك إلى حذف الكلمة المفتاحية بشكل نهائي, هل أنت متأكد من أنك تريد الحذف",
    "delete_title_ads": "أنت على وشك حذف هذا الاعلان",
    "delete_subtitle_ads": "أنت على وشك حذف الاعلان ,سيؤدي ذلك إلى حذف الاعلان بشكل نهائي, هل أنت متأكد من أنك تريد الحذ",
    "delete_title_applications": "أنت على وشك حذف هذا التطبيق",
    "delete_subtitle_applications": "أنت على وشك حذف التطبيق ,سيؤدي ذلك إلى حذف التطبيق بشكل نهائي, هل أنت متأكد من أنك تريد الحذ",
    "delete_title_main-services": "أنت على وشك حذف هذه الخدمة الرئيسية",
    "delete_subtitle_main-services": "أنت على وشك حذف الخدمة الرئيسية ,سيؤدي ذلك إلى حذف الخدمة الرئيسية بشكل نهائي, هل أنت متأكد من أنك تريد الحذ",
    "delete_title_contacts": "أنت على وشك حذف هذه البيانات",
    "delete_subtitle_contacts": "أنت على وشك حذف البيانات ,سيؤدي ذلك إلى حذف البيانات بشكل نهائي, هل أنت متأكد من أنك تريد الحذ"
  },
  "filter": {
    "read": "مقروء",
    "unread": "غير مقروء",
    "start_date": "من",
    "end_date": "الى",
    "start_range": "",
    "end_range": "",
    "status": "الحالة",
    "active": "مفعله",
    "disabled": "ملغية",
    "gender": "النوع",
    "male": "ذكر",
    "female": "انثى",
    "date": "تاريخ الاضافة",
    "type": "النوع",
    "spent": "نقاط مستهلكة",
    "acquire": "نقاط مكتسبة",
    "roles": "الصلاحيات",
    "companies": "الشركه",
    "folders": "المجلدات",
    "sections": "الاقسام",
    "managements": "الادارات"
  },
  "periods": {
    "monday": "الاثنين",
    "tuesday": "الثلاثاء",
    "wednesday": "الأربعاء",
    "thursday": "الخميس",
    "friday": "الجمعة",
    "saturday": "السبت",
    "sunday": "الأحد",
    "january": "يناير",
    "february": "فبراير ",
    "march": "مارس",
    "april": "أبريل",
    "may": "مايو",
    "june": "يونيو",
    "july": "يوليو",
    "august": "أغسطس",
    "september": "سبتمبر",
    "october": "أكتوبر",
    "november": "نوفمبر",
    "december": "ديسمبر"
  }
}