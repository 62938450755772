{
  "login": {
    "title": "تسجيل الدخول",
    "subtitle": "يرجى ادخال بيانات الدخول الخاصة بك",
    "forgot_password": "هل نسيت كلمة المرور؟",
    "login": "تسجيل الدخول"
  },
  "forgot_password": {
    "title": "إستعادة كلمة المرور",
    "subtitle": "يرجى ادخال البريد الالكترونى المسجل لدينا من قبل",
    "login_page": "صفحة تسجيل الدخول",
    "or": "او"
  },
  "reset_code": {
    "title": "رمز إعادة التعيين",
    "subtitle": "يرجى ادخال الرمز المرسل إلى إيميلك",
    "nothing_received": "لم يصل أي شيء"
  },
  "reset_password": {
    "title": "إعادة تعيين كلمة المرور",
    "subtitle": "يرجى ادخال كلمة المرور الجديدة لحسابك"
  }
}