{
  "seo": {
    "title": "تفعيل وضع الـ SEO للصفحة الحالية",
    "description": "يمكنك تخصيص الـ SEO لهذه الصفحة بشكل فردي",
    "btn": "تخصيص الإعدادات",
    "form": {
      "title": "عنوان الصفحة",
      "description": "وصف الصفحة",
      "keywords": "الكلمات الدلالية",
      "keywords_placeholder": "قم بإختيار الكلمات الدلالية المناسبة للصفحة",
    }
  }
}

