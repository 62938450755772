{
  "header": {
    "notifications": "notifications",
    "show_all_notifications": "Show all notifications",
    "change_language": "Change Language"
  },
  "sidebar": {
    "home": "home",
    "companies": "companies",
    "clients": "users",
    "advertisements": "advertisements",
    "notifications": "notifications",
    "settings": "settings",
    "platform": "platform settings",
    "system-roles": "Rules",
    "system-users": "System Users",
    "user_profile": "Profile Settings",
    "site-content": "Site Content",
    "library": "Media Center",
    "support": "technical support",
    "credit-cards": "Cards",
    "locations": "the location of the bank",
    "services": "services",
    "jawaher": "jawaher trading"
  },
  "footer": {
    "all_rights_reserved": "All rights reserved to YCB",
    "made_by": "made by",
    "wameed": "Wameed"
  }
}