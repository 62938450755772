{
  "common": {
    "hover_image": "Image when hover",
    "no_results": "No Results",
    "number": "number",
    "images": "images",
    "uploadImage": "Drag the image here or click here to add the image",
    "ar": "Arabic",
    "en": "english",
    "default": "default",
    "new": "new",
    "order_by": "order by",
    "newest": "newest first",
    "older": "oldest first",
    "alphabetical": "A-Z",
    "show_results": "Show Results From",
    "show_details": "Show Details",
    "filter_data": "Filter Items",
    "search": "search for data",
    "back": "back",
    "main_information": "Main Data",
    "social_information": "Social Media",
    "upload": "add",
    "optional": "optional",
    "folders_management": "Folders Management",
    "order": "order",
    "ok": "ok"
  },
  "actions": {
    "cancel": "cancel",
    "send": "send",
    "save": "save",
    "add": "add",
    "save_updates": "save changes",
    "show_all": "show all",
    "resend": "resend",
    "reset": "reset",
    "apply": "apply",
    "confirm": "confirm",
    "delete": "delete",
    "edit": "edit",
    "view": "view"
  },
  "no_data": {
    "no_items": "There are no items",
    "no_item_message": "Sorry, you haven't added any items yet",
    "no_data": "There is no data",
    "no_data_message": "No data has been submitted yet",
    "page_notfound": "Sorry, this page is not available",
    "page_notfound_message": "Sorry, this page does not exist, it may have been deleted, or moved to a new location.",
    "backhome": "back to home",
    "internet_connection": "There is a problem with the connection",
    "inernet_connection_message": "Please check your internet connection and refresh the page",
    "refresh": "refresh",
    "no_notifications": "There are no notifications",
    "no_notifications_message": "Sorry, no notifications have been sent yet",
    "no_more_results": "No More Data!",
    "no_permissions": "You have no permission",
    "no_permissions_message": "Sorry, you do not have permission to view the content"
  },
  "forms": {
    "search": {
      "label": "search"
    },
    "phone_number": {
      "label": "phone number"
    },
    "phone": {
      "label": "contact number"
    },
    "email": {
      "label": "email"
    },
    "password": {
      "label": "password"
    },
    "password_confirmation": {
      "label": "Confirm Password"
    },
    "new_password": {
      "label": "New Password"
    },
    "old_password": {
      "label": "your current password"
    },
    "confirm_new_password": {
      "label": "Confirm your new password"
    },
    "file": {
      "placeholder": "file Attachment"
    },
    "company_logo": {
      "label": "company logo",
      "placeholder": "Picture Attachment"
    },
    "company_name": {
      "label": "Company Name"
    },
    "company_description": {
      "label": "Company Description"
    },
    "point_collection_ways": {
      "label": "Methods of obtaining points"
    },
    "point_use_intraction": {
      "label": "Ways to use points"
    },
    "notification_name": {
      "label": "the name of the notice"
    },
    "notification_title": {
      "label": "The title of the notice"
    },
    "notification_image": {
      "label": "Notice Image"
    },
    "redirect": {
      "label": "direction",
      "placeholder": "Company Details"
    },
    "notification_content": {
      "label": "Notice Content"
    },
    "ads_title": {
      "label": "Name of the advertisement"
    },
    "ads_image": {
      "label": "ad image"
    },
    "platform_logo": {
      "label": "the logo of the platform"
    },
    "platform_name": {
      "label": "platform name"
    },
    "platform_description": {
      "label": "platform description"
    },
    "facebook": {
      "label": "Facebook"
    },
    "instagram": {
      "label": "Instagram"
    },
    "twitter": {
      "label": "Twitter"
    },
    "youtube": {
      "label": "youtube"
    },
    "telegram": {
      "label": "Telegram"
    },
    "linkedIn": {
      "label": "LinkedIn"
    },
    "role": {
      "label": "the label",
      "placeholder": "Specify the validity name"
    },
    "role_name": {
      "label": "name",
      "placeholder": "Type the name of the authority"
    },
    "user_name": {
      "label": "Username"
    },
    "profile_image": {
      "label": "personal photo"
    },
    "profile_images": {
      "label": "Profile Pictures",
      "placeholder": "Picture Attachment"
    },
    "full_name": {
      "label": "full name"
    },
    "message_title": {
      "label": "message title"
    },
    "message_content": {
      "label": "the body of the message"
    },
    "contact_email": {
      "label": "Contact Mail"
    },
    "support_email": {
      "label": "Support Mail"
    },
    "verification_code": {
      "label": "Verification Code"
    },
    "other_phone_number": {
      "label": "another phone number"
    }
  },
  "modal": {
    "logout": "You are about to log out",
    "logout_btn": "logout",
    "logout_message": "When you log out, you will only be able to access the control panel with the password. Are you sure of that?",
    "confirm_updating_profile": "Do you want to update your profile",
    "confirm_updating_profile_message": "Your data will be changed for the new entries you add",
    "delete_title_companies": "You are about to configure the company completely",
    "delete_subtitle_companies": "The entire configuration deactivates existing users and does not limit the ability to subscribe to the company",
    "stop_title_companies": "You are about to unsubscribe",
    "stop_subtitle_companies": "Stop subtitle allows existing users to access, but does not allow new users to join",
    "delete_title_clients": "Delete User",
    "delete_subtitle_clients": "You are about to delete the user, this will permanently delete the user, are you sure you want to delete",
    "delete_title_advertisements": "You are about to delete this ad",
    "delete_subtitle_advertisements": "You are about to delete the ad, this will permanently delete the ad, are you sure you want to delete",
    "delete_title_system_roles": "You are about to delete this permission",
    "delete_subtitle_system_roles": "You are about to delete the permission, this will delete the permission permanently, are you sure you want to delete",
    "delete_title_system_users": "You are about to delete this user",
    "delete_subtitle_system_users": "You are about to delete the user, this will permanently delete the user, are you sure you want to delete",
    "send_notification": "Send Notification",
    "send_notification_subtitle": "Upon approval, the notification will be sent to all users and you will not be able to undo this notification",
    "delete_title_videos": "You are about to delete this video",
    "delete_subtitle_videos": "You are about to delete the video ,this will permanently delete the video, are you sure you want to delete",
    "delete_title_folders": "You are about to delete this folder",
    "delete_subtitle_folders": "You are about to delete the folder ,this will permanently delete the folder, are you sure you want to delete",
    "delete_title_photos": "You are about to delete this photo",
    "delete_subtitle_photos": "You are about to delete the image, this will permanently delete the image, are you sure you want to delete",
    "delete_title_reports": "You are about to delete this report",
    "delete_subtitle_reports": "You are about to delete the report, this will permanently delete the report, are you sure you want to delete",
    "delete_title_magazines": "You are about to delete this magazine",
    "delete_subtitle_magazines": "You are about to delete the magazine, this will permanently delete the magazine, are you sure you want to delete",
    "delete_title_sections": "You are about to delete this section",
    "delete_subtitle_sections": "You are about to delete the section ,this will permanently delete the section, are you sure you want to delete",
    "delete_title_credit_cards": "You are about to delete this card",
    "delete_subtitle_credit_cards": "You are about to delete the card ,this will permanently delete the card, are you sure you want to delete",
    "delete_title_news": "You are about to delete this story",
    "delete_subtitle_news": "You are about to delete the story, this will permanently delete the story, are you sure you want to delete it?",
    "delete_title_faq": "You are about to delete this question",
    "delete_subtitle_faq": "You are about to delete the question, this will delete the question permanently, are you sure you want to delete",
    "delete_title_slider": "You are about to delete this ad",
    "delete_subtitle_slider": "You are about to delete the ad, this will permanently delete the ad, are you sure you want to delete",
    "delete_title_branch": "You are about to delete this branch",
    "delete_subtitle_branch": "You are about to delete the branch ,this will permanently delete the branch, are you sure you want to delete",
    "delete_title_atm": "You are about to delete this atm",
    "delete_subtitle_atm": "You are about to delete the subtitles, this will permanently delete the subtitles, are you sure you want to delete",
    "delete_title_pos": "You are about to delete the POS",
    "delete_subtitle_pos": "You are about to delete the POS ,this will permanently delete the POS, are you sure you want to delete",
    "delete_title_management": "You are about to delete this management",
    "delete_subtitle_management": "You are about to delete the subtitle. This will permanently delete the subtitle. Are you sure you want to delete?",
    "delete_title_staff": "You are about to delete this employee",
    "delete_subtitle_staff": "You are about to delete the employee, this will permanently delete the employee, are you sure you want to delete",
    "delete_title_sub-services": "You are about to delete this sub-service",
    "delete_subtitle_sub-services": "You are about to delete a subtitle ,this will permanently delete the subtitle, are you sure you want to delete",
    "delete_title_sub-subservices": "You are about to delete this sub-service",
    "delete_subtitle_sub-subservices": "You are about to delete a subtitle ,this will permanently delete the subtitle, are you sure you want to delete",
    "delete_title_terms": "You are about to delete these terms and features",
    "delete_subtitle_terms": "You are about to delete the terms and conditions, this will permanently delete the terms and conditions, are you sure you want to delete",
    "delete_title_awards": "You are about to delete these awards",
    "delete_subtitle_awards": "You are about to delete awards ,this will permanently delete awards, are you sure you want to delete",
    "delete_title_draws": "You are about to delete these draws",
    "delete_subtitle_draws": "You are about to delete the draws, this will permanently delete the draws, are you sure you want to delete",
    "delete_title_winners": "You are about to delete this winner",
    "delete_subtitle_winners": "You are about to delete the winner, this will permanently delete the winner, are you sure you want to delete",
    "delete_title_message": "You are about to delete this message",
    "delete_subtitle_message": "You are about to delete the message, this will permanently delete the message, are you sure you want to delete",
    "delete_title_messages": "You are about to delete these messages",
    "delete_subtitle_messages": "You are about to delete messages, this will permanently delete messages, are you sure you want to delete",
    "delete_title_keywords": "You are about to delete this keyword",
    "delete_subtitle_keywords": "You are about to delete the keyword, this will delete the keyword permanently, are you sure you want to delete",
    "delete_title_ads": "You are about to delete this ad",
    "delete_subtitle_ads": "You are about to delete the ad. This will permanently delete the ad. Are you sure you want to delete it?",
    "delete_title_applications": "You are about to delete this application",
    "delete_subtitle_applications": "You are about to delete the app, this will permanently delete the app, are you sure you want to delete",
    "delete_title_main-services": "You are about to delete this service",
    "delete_subtitle_main-services": "You are about to delete the service, this will permanently delete the service, are you sure you want to delete",
    "delete_title_contacts": "You are about to delete this contact",
    "delete_subtitle_contacts": "You are about to delete the contact, this will permanently delete the contact, are you sure you want to delete"
  },
  "filter": {
    "read": "read",
    "unread": "unread",
    "start_date": "from",
    "end_date": "to",
    "start_range": "",
    "end_range": "",
    "status": "status",
    "active": "enabled",
    "disabled": "cancelled",
    "gender": "the gender",
    "male": "male",
    "female": "female",
    "date": "date added",
    "type": "type",
    "spent": "points spent",
    "acquire": "Points Earned",
    "roles": "powers",
    "companies": "the company",
    "folders": "folders",
    "sections": "sections",
    "managements": "departments"
  },
  "periods": {
    "monday": "monday",
    "tuesday": "tuesday",
    "wednesday": "Wednesday",
    "thursday": "thursday",
    "friday": "friday",
    "saturday": "Saturday",
    "sunday": "sunday",
    "january": "January",
    "february": "february",
    "march": "march",
    "april": "april",
    "may": "may",
    "june": "June",
    "july": "july",
    "august": "august",
    "september": "september",
    "october": "october",
    "november": "November",
    "december": "december"
  }
}