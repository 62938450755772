{
  "wameed_notifications": {
    "success": {
      "information_saved": "Data saved successfully",
      "password_changed": "Data saved successfully",
      "password_sent": "Data saved successfully"
    },
    "fail": {
      "01_01": "There is no account registered with this email.",
      "01_02": "The password is incorrect.",
      "01_03": "There is an account registered with this email.",
      "01_04": "Invalid token.",
      "01_05": "The old password is incorrect.",
      "01_06": "Session ended",
      "01_07": "Verify that the code is correct",
      "01_08": "This user has been banned",
      "01_09": "There is an account registered with this number.",
      "01_13": "You cannot change the status of this user",
      "02_01": "The title is already in use",
      "error": "Invalid request",
      "400": "Verification code is invalid",
      "401": "Session ended",
      "404": "unavailable",
      "403": "You have no permission.",
      "422": "Ensure that the entry is correct.",
      "500": "Try again later or contact support",
      "save": "There was a problem while saving",
      "imageError": "No more than 3 photos can be added",
      "toggle": "There was a problem changing the state",
      "toggle_role": "You cannot change the status of this permission",
      "update_role": "You cannot update this permission",
      "delete_role": "You cannot delete this permission"
    }
  }
}