{
  "support": {
    "empty": "no mail",
    "news_letter": "Newsletter Address List",
    "mark-read": "mark as read",
    "reply": "Write your response here",
    "side": {
      "trash": "trash",
      "draft": "drafts",
      "star": "featured",
      "sent-mail": "sent-mail",
      "inbox": "inbox",
      "all": "all mail",
      "folders": "Theme Type",
      "all_folders": "all"
    },
    "table": {
      "name": "Name",
      "subject": "subject",
      "message": "the message",
      "created_at": "Date Created",
      "message_type": "Message Type"
    }
  }
}