{
  "site-content": {
    "slider": {
      "title": "سلايد شو",
      "description": "عرض الإعلانات في الصفحة الرئيسية"
    },
    "info": {
      "title": "عن البنك",
      "description": "الأهداف , الرؤى , الشروط , السياسات, بيانات التواصل"
    },
    "home": {
      "title": "محتوى الصفحة الرئيسية",
      "description": "تفاصيل المحتوى للصفحة الرئيسية"
    },
    "news": {
      "title": "الأخبار",
      "description": "عرض الأخبار في الصفحة الرئيسية"
    },
    "management": {
      "title": "مجلس الإدارة",
      "description": "الأعضاء وفريق العمل"
    },
    "faq": {
      "title": "الأسئلة الشائعة",
      "description": "بيانات الأسئلة الشائعة"
    },
    "policy": {
      "title": "سياسة الالتزام",
      "description": "الأمور النصة القانونية"
    },
    "social": {
      "title": "مواقع التواصل الاجتماعي",
      "description": "فيسبوك , تويتر , انستجرام"
    },
    "application": {
      "title": "تطبيقات البنك",
      "description": "عرض التطبيقات في الصفحة الرئيسية"
    },
    "ads": {
      "title": "الإعلانات المصغرة",
      "description": "قسم الإعلانات المصغرة في الصفحة الرئيسية"
    }
  },
  "extra": {
    "title": "محتوى الصفحة الرئيسية",
    "update": {
      "title": "تحديث البيانات"
    }
  }
}

